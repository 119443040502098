import React from "react";
import "../App.css";
import "./About.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about-wrapper">
      <div className="about-container">
        <h1>Om Oss</h1>
        <hr className="horizontal-line" />
        <p>
          CM Bygghjälp AB grundades 2020 och vi har redan färdigställt ett antal
          mindre och större projekt runt om i Helsingborg och nordvästra Skåne
          (se våra{" "}
          <Link to="/referenser" className="link">
            referensobjekt
          </Link>
          ). Vi utför mycket inom bygg såsom renoveringar, till- och
          ombyggnader, tak, altan och byggservice med mera. Vi erbjuder även
          tjänster inom grävning, markarbeten, stubbfräsning, dränering och
          avlopp.
        </p>
        <p>
          Vår byggservice kännetecknas av hög tillgänglighet, att det är lätt
          att få tag i oss, att vi är snabbt på plats och att vi har nära
          kontakt med våra kunder.
        </p>
        <p>
          Du är alltid välkommen att anlita oss för ditt byggprojekt.{" "}
          <Link to="/kontakt" className="link">
            Hör av dig
          </Link>{" "}
          till CM Bygghjälp!
        </p>
        <div className="avatar-image">
          <img src="/images/christian.jpg" alt="Christian Mas" />
        </div>
        <p>
          <b>Christian Mas</b> - VD, grundare och kreatör
        </p>
        <div className="avatar-image">
          <img src="/images/kontor-lager-florettgatan.jpg" alt="Kontor/lager" />
        </div>
        <p>Kontor och lager</p>
      </div>
    </div>
  );
};

export default About;
