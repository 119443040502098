import React from "react";
import "../App.css";
import "./References.css";
import ReferenceCard from "../components/ReferenceCard";

const References = () => {
  return (
    <div className="references-wrapper">
      <div className="references-container">
        <h1>Referenser</h1>
        <hr className="horizontal-line" />
        <div className="cards-container">
          <ReferenceCard
            img="/images/ramlosa-23/tillbyggnad-5.jpg"
            location="Ramlösa"
            category="Tillbyggnad"
            route="/referenser/ramlosa2023"
          />
          <ReferenceCard
            img="/images/jonstorp-21/trädäck-2.jpg"
            location="Jonstorp"
            category="Trädäck och uterum"
            route="/referenser/jonstorp2021"
          />
          <ReferenceCard
            img="/images/höganäs-21-3/taklaggning-1.jpg"
            location="Höganäs"
            category="Takläggning"
            route="/referenser/hoganas2021-3"
          />
          <ReferenceCard
            img="/images/kingelstad-21/stensattning-1.jpg"
            location="Kingelstad"
            category="Stensättning"
            route="/referenser/kingelstad2021"
          />
          <ReferenceCard
            img="/images/rydebäck-21/tradack-4.jpg"
            location="Rydebäck"
            category="Trädäck"
            route="/referenser/rydeback2021"
          />
          <ReferenceCard
            img="/images/allerum-21/taklaggning-2.jpg"
            location="Allerum"
            category="Takläggning"
            route="/referenser/allerum2021"
          />
          <ReferenceCard
            img="/images/björkdala-21/altan-7.jpg"
            location="Björkdala"
            category="Trädäck, sten och gräsmatta"
            route="/referenser/bjorkdala2021"
          />
          <ReferenceCard
            img="/images/höganäs-21/sargsten-2.jpg"
            location="Höganäs"
            category="Sargsten"
            route="/referenser/hoganas2021"
          />
          <ReferenceCard
            img="/images/påarp-20/uterum-3.jpg"
            location="Påarp"
            category="Uterum"
            route="/referenser/paarp2020"
          />
          <ReferenceCard
            img="/images/tagaborg-21/sargsten-5.jpg"
            location="Tågaborg"
            category="Sargsten"
            route="/referenser/tagaborg2021"
          />
          <ReferenceCard
            img="/images/björkdala-20/dranering-1.jpg"
            location="Björkdala"
            category="Dränering"
            route="/referenser/bjorkdala2020"
          />
          <ReferenceCard
            img="/images/höganäs-21-2/gavel-4.jpg"
            location="Höganäs"
            category="Gavel och vindskivor"
            route="/referenser/hoganas2021-2"
          />
          <ReferenceCard
            img="/images/larod-20/poolhus-3.jpg"
            location="Laröd"
            category="Poolhus"
            route="/referenser/larod2020"
          />
          <ReferenceCard
            img="/images/allerum-20/pool-1.jpg"
            location="Allerum"
            category="Pool"
            route="/referenser/allerum2020"
          />
        </div>
      </div>
    </div>
  );
};

export default References;
