import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import References from "./pages/References";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ReferenceInfo from "./components/ReferenceInfo";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/referenser" exact component={References} />
          <Route path="/referenser/:id">
            <ReferenceInfo />
          </Route>
          <Route path="/om" component={About} />
          <Route path="/kontakt" component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
