import React from "react";
import "../App.css";
import "./Contact.css";
import ContactAlternatives from "../components/ContactAlternatives";

const Contact = () => {
  return (
    <div className="contact-wrapper">
      <div className="contact-container">
        <div className="contact-info">
          <h1>Kontakta oss</h1>
          <hr className="horizontal-line" />
          <p>
            Är du intresserad av att få en offert på ditt projekt eller har du
            övriga frågor? Välkommen att kontakta oss på något av nedanstående
            sätt!
          </p>
        </div>

        <ContactAlternatives />

        <div className="company-info">
          <p>CM Bygghjälp & Transport AB</p>
          <p>Org.nr: 559267-7685</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
