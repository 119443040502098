import React from "react";
import "../App.css";
import "./Home.css";
import Hero from "../components/Hero";
import ReferenceCard from "../components/ReferenceCard";

const Home = () => {
  return (
    <div className="home-wrapper">
      <Hero />
      <section className="home-references">
        <h1>Några av våra utförda projekt</h1>
        <hr className="horizontal-line" />
        <div className="references-container">
          <ReferenceCard
            img="/images/kingelstad-21/stensattning-1.jpg"
            location="Kingelstad"
            category="Stensättning"
            route="/referenser/kingelstad2021"
          />
          <ReferenceCard
            img="/images/rydebäck-21/tradack-4.jpg"
            location="Rydebäck"
            category="Trädäck"
            route="/referenser/rydeback2021"
          />
          <ReferenceCard
            img="/images/allerum-21/taklaggning-2.jpg"
            location="Allerum"
            category="Takläggning"
            route="/referenser/allerum2021"
          />
        </div>
      </section>
      <section className="home-partners">
        <h1>Samarbetspartners</h1>
        <hr className="horizontal-line" />
        <div className="home-partners-container">
          <div className="partner">
            <img src="/images/optimera_logo.png" alt="referensbild" />
          </div>
          <div className="partner">
            <img src="/images/ecopool_logo.png" alt="referensbild" />
          </div>
          <div className="partner">
            <h2>JH Truckservice</h2>
          </div>
          <div className="partner">
            <h2>M-Sjö Bygg</h2>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
