import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer>
      <h1>CM Bygghjälp</h1>
      <ul className="footer-links">
        <li>
          <Link to="/">Start</Link>
        </li>
        |
        <li>
          <Link to="/referenser">Referenser</Link>
        </li>
        |
        <li>
          <Link to="/om">Om Oss</Link>
        </li>
        |
        <li>
          <Link to="/kontakt">Kontakt</Link>
        </li>
      </ul>
      <ul className="footer-contact-alts">
        <li>
          <FontAwesomeIcon icon={faPaperPlane} size="1x" />

          <a href="mailto:christianmas1@gmail.com">christianmas1@gmail.com</a>
        </li>
        <li>
          <FontAwesomeIcon icon={faPhone} size="1x" />
          <a href="tel:+46739874777">073-987 47 77</a>
        </li>
      </ul>
      <p className="credits">&copy; 2023 CM Bygghjälp & Transport AB</p>
    </footer>
  );
};

export default Footer;
