import React from "react";
import "./ReferenceInfo.css";
import { useParams } from "react-router-dom";
import references from "../data/references.json";

const ReferenceInfo = () => {
  const { id } = useParams();
  const referenceInfo = references.find((reference) => reference.id === id);

  if (!referenceInfo) {
    return null;
  }

  const { imageSources, location, year, type } = referenceInfo;

  return (
    <div className="reference-info-wrapper">
      <div className="reference-info-container">
        <div className="reference-text">
          <h1>{type}</h1>
          <h3>
            {location}, {year}
          </h3>

          <hr className="horizontal-line" />
        </div>
        <div className="reference-image-container">
          {imageSources.map((image, index) => (
            <div className="reference-image" key={index}>
              <img src={image} alt="referensbild" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReferenceInfo;
