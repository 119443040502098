import React from "react";
import Button from "../components/Button";
import "./ReferenceCard.css";
import { Link } from "react-router-dom";

const ReferenceCard = ({ img, location, category, route }) => {
  return (
    <div className="card">
      <div className="card-image">
        <Link to={route}>
          <img src={img} alt="referensbild" />
        </Link>
      </div>
      <div className="text">
        <div className="reference">{category}</div>
        <p>{location}</p>
        <Button
          className="button"
          route={route}
          buttonStyle="btn--primary"
          buttonSize="btn--medium"
        >
          LÄS MER
        </Button>
      </div>
    </div>
  );
};

export default ReferenceCard;
