import React from "react";
import Button from "./Button";
import "../App.css";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="section">
      <div className="hero-container">
        <h1>CM Bygghjälp - En byggfirma i nordvästra Skåne</h1>
        <p className="hero-services">
          Bygg&nbsp;&nbsp;|&nbsp;&nbsp; Renovering&nbsp;&nbsp;|&nbsp;&nbsp;
          Gräv&nbsp;&nbsp;|&nbsp;&nbsp; Uppfart&nbsp;&nbsp;|&nbsp;&nbsp;
          Uteplats&nbsp;&nbsp;|&nbsp;&nbsp; Avlopp
        </p>
        <div className="hero-btns">
          <Button
            className="btns"
            buttonStyle="btn--outlined"
            buttonSize="btn--large"
            route="/om"
          >
            LÄS MER
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            route="/kontakt"
          >
            KONTAKT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
