import React from "react";
import "./ContactAlternatives.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";

const ContactAlternatives = () => {
  return (
    <div>
      <div className="contact-alt-container">
        <div className="contact-alt email">
          <FontAwesomeIcon
            icon={faPaperPlane}
            className="contact-icon"
            size="2x"
          />
          <h3>Email</h3>
          <a href="mailto:christianmas1@gmail.com">christianmas1@gmail.com</a>
        </div>
        <div className="contact-alt phone">
          <FontAwesomeIcon icon={faPhone} className="contact-icon" size="2x" />
          <h3>Telefon</h3>
          <a href="tel:+46739874777">073-987 47 77</a>
        </div>
        <div className="contact-alt address">
          <FontAwesomeIcon
            icon={faAddressBook}
            className="contact-icon"
            size="2x"
          />
          <h3>Adress</h3>
          <p>Fridas Gata 167</p>
          <p>256 54 Ramlösa</p>
        </div>
      </div>
    </div>
  );
};

export default ContactAlternatives;
